import React from "react"

function LinkArrow({ className }) {
  return (
    <div className={className}>
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path d="M3.33342 10.8333L13.4751 10.8333L8.81675 15.4917L10.0001 16.6667L16.6667 10L10.0001 3.33334L8.82508 4.50834L13.4751 9.16668L3.33342 9.16668V10.8333Z" fill="url(#paint0_linear_260_567)"/>
        <defs>
          <linearGradient id="paint0_linear_260_567" x1="16.6667" y1="3.33334" x2="3.33342" y2="3.33334" gradientUnits="userSpaceOnUse">
            <stop stop-color="#FFB533"/>
            <stop offset="1" stop-color="#FFA600"/>
          </linearGradient>
        </defs>
      </svg>
    </div>
  )
}

export { LinkArrow }