import React from "react"
import { withStyles } from "@material-ui/core"
import Typography from "@material-ui/core/Typography"
import { LinkWithArrow } from "./link-with-arrow"
import { Link } from "gatsby"
import { useFluidUrl } from "../../images"


function PostPreviewItem(props) {
  const { classes, previewImg, title, date, link } = props
  const imgSrc = useFluidUrl(previewImg)

  if (!link) return null

  return (
    <Link to={link} target="_blank">
      <div className={classes.card}>
        <div className={classes.preview}>
          <img src={imgSrc.src} alt=""/>
        </div>
        <div className={classes.info}>
          <div>
            <Typography
              variant="body1"
              className={classes.date}
              children={date}
            />

            <Typography
              variant="h4"
              className={classes.title}
              children={title}
            />
          </div>

          <LinkWithArrow
            text="Learn more"
            link={link}
          />
        </div>
      </div>
    </Link>
  )
}

const styles = theme => ({
  card: {
    border: "1px solid #DCE5EB",
    borderRadius: 10,
    overflow: "hidden",
    minHeight: 455,
    height: "100%",
    display: "flex",
    flexDirection: "column",

    "&:hover $preview img": {
      transform: "scale(1.1)"
    }
  },
  info: {
    padding: "20px 20px 10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flex: 1
  },
  date: {
    color: theme.palette.text.primary,
    fontSize: 18,
    lineHeight: "24px",
    fontWeight: 400,
    marginBottom: 5
  },
  title: {
    color: theme.palette.text.secondary,
    fontWeight: 700,
    marginBottom: 10,
    lineHeight: "32px",
    letterSpacing: "-0.5px",
    "-webkit-line-clamp": 3,
    display: "-webkit-box",
    "-webkit-box-orient": "vertical",
    overflow: "hidden"
  },
  preview: {
    fontSize: 0,
    overflow: "hidden",
    maxHeight: 240,

    "& img": {
      width: "100%",
      transition: "all .3s ease-out"
    }
  },
})

// eslint-disable-next-line
PostPreviewItem = withStyles(styles)(PostPreviewItem)

export { PostPreviewItem }
