import React from "react"
import { withStyles } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import { PostPreviewItem } from "../molecules/post-preview-item"

function ArticlesList(props) {
  const { classes, posts } = props

  function renderPostPreviewItem ({node}, key) {
    const title = node.frontmatter.title || node.fields.slug
    const date = node.frontmatter.date
    const link = node.fields.slug
    const previewImg = node.frontmatter.preview || ''

    return (
      <Grid
        item
        key={key}
        md={4}
        sm={12}
      >
        <PostPreviewItem
          title={title}
          date={date}
          link={link}
          previewImg={previewImg}
        />
      </Grid>
    )
  }

  return (
    <Grid
      container
      spacing={6}
    >
      {posts.map(renderPostPreviewItem)}
    </Grid>
  )

}

const styles = theme => {
  return {}
}

// eslint-disable-next-line
ArticlesList = withStyles(styles)(ArticlesList)

export { ArticlesList }
